import {
  byEnvironment,
  EnvironmentMapperFn,
  ENVIRONMENTS,
} from './environments';

export type LoggerFn = (message: string | object) => void;

export function createLogger(
  getEnv: EnvironmentMapperFn,
  clientId: string,
  relay = '',
  name = 'default'
): LoggerFn {
  const env = byEnvironment(getEnv);

  const endpoint = env({
    [ENVIRONMENTS.local]: () => 'https://localhost.corp.adobe.com:8081/signin',
    [ENVIRONMENTS.stage]: () =>
      'https://auth-stg1.services.adobe.com/signin/v1/audit',
    [ENVIRONMENTS.prod]: () =>
      'https://auth.services.adobe.com/signin/v1/audit',
  });

  const envName = env({
    [ENVIRONMENTS.local]: () => 'susi-light-local',
    [ENVIRONMENTS.stage]: () => 'susi-light-stage',
    [ENVIRONMENTS.prod]: () => 'susi-light-prod',
  });

  return function logger(message: string | object): void {
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-IMS-CLIENTID': clientId,
        'X-DEBUG-ID': relay,
      },
      body: JSON.stringify({
        name,
        envName,
        clientId,
        message,
      }),
    }).catch(e => {});
  };
}
