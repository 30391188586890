import { environmentMapper, ENVIRONMENTS } from './environments';

export const getWrapperEnv = environmentMapper({
  'https://localhost.corp.adobe.com:8080': ENVIRONMENTS.local,
  'https://auth-light-sample.identity-stage.adobe.com': ENVIRONMENTS.stage,
});

export const getAppEnv = environmentMapper({
  'https://localhost.corp.adobe.com:25000': ENVIRONMENTS.local,
  'https://auth-light.identity-stage.adobe.com': ENVIRONMENTS.stage,
});
