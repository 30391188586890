type ObjectValues<T> = T[keyof T];

export const ERROR_REASONS = {
  undefined: 'undefined',
  critical: 'critical',
  generic: 'generic',
  unrecoverable: 'unrecoverable',
};

export type ErrorReason = ObjectValues<typeof ERROR_REASONS>;

function toStringError(error?: Error | SentryError): string {
  if (error instanceof SentryError) {
    return error.toString();
  }

  if (error instanceof Error) {
    const message = error.message ? `: ${error.message}` : '';
    return error.name + message;
  }

  return '';
}

export class SentryError extends Error {
  public type = ERROR_REASONS.undefined;
  public cause: string = '';
  public originalError?: SentryError | Error;

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      stack: this.stack,
    };
  }

  toString() {
    const error = `${this.name}: ${this.message}`;
    const originalErrorString = toStringError(this.originalError).replaceAll(
      '\n',
      '\n\t'
    );

    return `${error}\n\t<- ${originalErrorString}`;
  }

  get name(): string {
    return this.type;
  }

  get stack(): string {
    return this.originalError?.stack ?? this.stack;
  }

  static extendError(sentryError: SentryError, originalError?: Error) {
    if (originalError && originalError instanceof Error) {
      sentryError.originalError = originalError;
    }

    return sentryError;
  }

  static generic(originalError: Error) {
    const error = new GenericError();
    return SentryError.extendError(error, originalError);
  }

  static critical(
    causeMessage: string,
    originalError?: Error
  ): UnrecoverableError {
    const error = new CriticalError(causeMessage);
    return SentryError.extendError(error, originalError);
  }

  static unrecoverable(
    causeMessage: string,
    originalError?: Error
  ): UnrecoverableError {
    const error = new UnrecoverableError(causeMessage);
    return SentryError.extendError(error, originalError);
  }
}

export class GenericError extends SentryError {
  public type: ErrorReason = ERROR_REASONS.generic;
}

export class CriticalError extends SentryError {
  public type: ErrorReason = ERROR_REASONS.critical;
}

export class UnrecoverableError extends SentryError {
  public type: ErrorReason = ERROR_REASONS.unrecoverable;
}

export function convertError(input: unknown): SentryError {
  if (input instanceof SentryError) {
    return input;
  }

  if (input instanceof Error) {
    return SentryError.generic(input);
  }

  return SentryError.generic(new Error(String(input)));
}
