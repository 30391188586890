import { SentryError } from './errors';

export function splitEvent(eventStr: string) {
  const prefixLength = eventStr.indexOf('-');
  const metaDataLength = eventStr.indexOf(':');

  const event = eventStr.substring(0, prefixLength);
  const side = eventStr.substring(prefixLength + 1, metaDataLength);
  const data = eventStr.substring(metaDataLength + 1);

  return {
    event,
    side,
    data,
  };
}

export class Event<T = unknown> {
  public readonly name: string;
  public readonly side: string;
  public data: T;

  constructor({ name, side, data }: { name: string; side: string; data: T }) {
    this.name = name;
    this.side = side;
    this.data = data;
  }

  setData(data: T): Event<T> {
    if (data) {
      return Event.create<T>({ name: this.name, side: this.side, data });
    } else {
      return this;
    }
  }

  compareTo<T>(event: Event<T>) {
    return this.name === event.name && this.side === event.side;
  }

  of<TAssert extends Event>(base: TAssert): this is TAssert {
    return this.compareTo(base);
  }

  toString() {
    return JSON.stringify(this);
  }

  static fromString(eventStr: string) {
    if (typeof eventStr !== 'string') {
      throw new TypeError("Event: Event couldn't be created. Invalid value.");
    }

    const parse = JSON.parse(eventStr) as object;
    if (
      parse &&
      Object.prototype.hasOwnProperty.call(parse, 'side') &&
      Object.prototype.hasOwnProperty.call(parse, 'name')
    ) {
      return new Event(JSON.parse(eventStr));
    } else {
      throw new TypeError(
        'Event: Event incorrectly formatted. Missing side or name.'
      );
    }
  }

  static parse<TAssert>(
    eventStr: string,
    assertedEvent: Event
  ): TAssert | null {
    const event = this.fromString(eventStr);
    if (event.compareTo(assertedEvent)) {
      return event as TAssert;
    } else {
      return null;
    }
  }

  static create(params: { name: string; side: string }): Event<undefined>;
  static create<T>(params: { name: string; side: string; data: T }): Event<T>;
  static create<T>(params: {
    name: string;
    side: string;
    data?: T;
  }): Event<T | undefined> {
    return new Event(params as { name: string; side: string; data: T });
  }
}

// CONNECTION
export const InitEvent = Event.create({ name: 'init', side: 'app' });
export const ResponseEvent = Event.create<Record<string, any>>({
  name: 'response',
  side: 'wrapper',
  data: {},
});
export const CompleteEvent = Event.create({ name: 'complete', side: 'app' });
export const UnrecoverableErrorEvent = Event.create<SentryError>({
  name: 'error',
  side: 'app',
  data: SentryError.unrecoverable('Unknown error.'),
});

export const OpenPopupEvent = Event.create<string>({
  name: 'open-popup',
  side: 'app',
  data: '',
});

export const OpenModalEvent = Event.create<{
  dimensions: { height: number; width: number };
  reason: string;
}>({
  name: 'on-modal-open',
  side: 'proxy',
  data: {
    dimensions: { height: 550, width: 400 },
    reason: 'unknown',
  },
});

export const CloseModalEvent = Event.create({
  name: 'on-modal-close',
  side: 'proxy',
});

export const OpenModalCompleteEvent = Event.create({
  name: 'open-modal-complete',
  side: 'wrapper',
});

export const OpenPopupCompleteEvent = Event.create({
  name: 'open-popup-complete',
  side: 'wrapper',
});

export const OpenPopupBlockedEvent = Event.create({
  name: 'open-popup-blocked',
  side: 'wrapper',
});

export const OpenPopupErrorEvent = Event.create<string>({
  name: 'open-popup-error',
  side: 'popup',
  data: '',
});

export const PopupQueryStateEvent = Event.create<string>({
  name: 'query-state',
  side: 'popup',
  data: '',
});

/**
  Page includes wrapper, establishConnection() - waits for init [<- INIT]
  Iframe loads, createConnection() - sends init event and listens for response [-> INIT, <- RESPONSE]
  Wrapper receives init with port, sends response, listens for complete [<- INIT, -> RESPONSE, <- COMPLETE]
  Iframe receives response, sends complete [<- RESPONSE, -> COMPLETE]
 */

export function createConnection(
  targetOrigin: string,
  targetWindow = window.parent
): Promise<{
  port: MessagePort;
  channel: MessageChannel;
  state: { [key: string]: any };
}> {
  return new Promise((resolve, reject) => {
    if (!targetOrigin) {
      reject(SentryError.unrecoverable('Invalid target origin.'));
    }
    // Send Init event and second port to establish connection
    const channel = new MessageChannel();
    targetWindow.postMessage(InitEvent.toString(), targetOrigin, [
      channel.port2,
    ]);

    // Wait for ResponseEvent from Wrapper
    channel.port1.onmessage = function (e) {
      const response = Event.parse<typeof ResponseEvent>(e.data, ResponseEvent);
      if (response) {
        channel.port1.postMessage(CompleteEvent.toString());

        // Resolve with Wrapper data and port
        resolve({ port: channel.port1, channel, state: response.data });
      } else {
        reject(
          UnrecoverableErrorEvent.setData(
            SentryError.unrecoverable('Wrong wrapper response.')
          )
        );
      }
    };
  });
}

export function establishConnection(
  allowedOrigin: string
): Promise<MessagePort> {
  return new Promise((resolve, reject) => {
    window.addEventListener('message', function listener(e) {
      if (e.origin !== allowedOrigin) return;

      const event = Event.fromString(e.data);
      if (event.of(InitEvent)) {
        resolve(e.ports[0]);
      }

      if (event.of(UnrecoverableErrorEvent)) {
        reject(event);
      }

      window.removeEventListener('message', listener);
    });
  });
}

export function completeConnection(
  port: MessagePort,
  state?: any
): Promise<MessagePort> {
  return new Promise((resolve, reject) => {
    // Wait for Complete event
    port.onmessage = function (e) {
      const event = Event.parse<typeof CompleteEvent>(e.data, CompleteEvent);
      if (event) {
        resolve(port);
      } else {
        reject(
          UnrecoverableErrorEvent.setData(
            SentryError.unrecoverable(
              'Invalid Sentry reponse to the connection.'
            )
          )
        );
      }
    };

    // Send Response event
    ResponseEvent.data = state;
    port.postMessage(ResponseEvent.toString());
  });
}

export function startListening(port: MessagePort) {
  const eventEmitter = new EventEmitter();

  port.onmessage = function (event) {
    eventEmitter.broadcast(Event.fromString(event.data));
  };
  port.onmessageerror = function () {
    eventEmitter.broadcast(
      UnrecoverableErrorEvent.setData(
        SentryError.unrecoverable('Port message error.')
      )
    );
  };

  window.addEventListener('storage', ({ key, newValue }) => {
    try {
      if (key === 'popupEvent' && newValue) {
        const event = Event.fromString(newValue);

        if (event.of(PopupQueryStateEvent)) {
          const returnEvent = Event.fromString(event.data);
          eventEmitter.broadcast(returnEvent);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (key) {
        localStorage.removeItem(key);
      }
    }
  });

  const eventScheduler = new EventScheduler(eventEmitter);
  return function () {
    return eventScheduler;
  };
}

export type EventHandler = (e: Event) => void;

export class EventEmitter {
  private listeners: Set<EventListener> = new Set();

  get numListeners() {
    return this.listeners.size;
  }

  add(listener: EventListener) {
    this.listeners.add(listener);
  }

  remove(listener: EventListener) {
    this.listeners.delete(listener);
  }

  broadcast(event: Event) {
    this.listeners.forEach(listener => {
      listener.emit(event);
    });
  }
}

export class EventScheduler {
  constructor(private emitter: EventEmitter) {}

  subscribe(fn: EventHandler): () => void {
    const listener = new EventListener(fn);
    this.emitter.add(listener);
    return () => {
      this.emitter.remove(listener);
    };
  }

  once(fn?: EventHandler): Promise<Event | void> {
    let disposer: () => void;
    const promise: Promise<Event> = new Promise(res => {
      disposer = this.subscribe(event => res(event));
    });

    promise.catch(() => disposer());

    if (fn) {
      return promise.then(event => fn(event)).then(() => disposer());
    } else {
      return promise;
    }
  }

  listenFor<T extends Event>(listenedEvent: T): Promise<T> {
    return new Promise((res, rej) => {
      const disposer = this.subscribe((event: Event) => {
        if (listenedEvent.compareTo(event)) {
          res(event as any);
          disposer();
        }
      });
    });
  }
}

export class EventListener {
  constructor(private listener: EventHandler) {}

  emit(event: Event) {
    this.listener(event);
  }
}

// APP EVENTS
export type EventSender<T> = T extends undefined
  ? (data?: T) => null
  : (data: T) => null;

export function eventFactory<T = undefined>(port: MessagePort) {
  return (event: Event): EventSender<T> =>
    ((data?: T extends undefined ? undefined : T) => {
      event.data = data as T;
      port.postMessage(event.toString());
      return null;
    }) as EventSender<T>;
}
