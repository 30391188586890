import {
  byEnvironment,
  EnvironmentMapperFn,
  ENVIRONMENTS,
} from './environments';

export function getApiUrl(getEnvironment: EnvironmentMapperFn) {
  return () =>
    byEnvironment(getEnvironment)<string>({
      [ENVIRONMENTS.local]: () => 'https://ims-na1-stg1.adobelogin.com',
      [ENVIRONMENTS.stage]: () => 'https://ims-na1-stg1.adobelogin.com',
      [ENVIRONMENTS.prod]: () => 'https://ims-na1.adobelogin.com',
    });
}

export function getFederatedUrl(getEnvironment: EnvironmentMapperFn) {
  return () =>
    byEnvironment(getEnvironment)<string>({
      [ENVIRONMENTS.local]: () =>
        'https://federatedid-na1-stg1.services.adobe.com',
      [ENVIRONMENTS.stage]: () =>
        'https://federatedid-na1-stg1.services.adobe.com',
      [ENVIRONMENTS.prod]: () => 'https://federatedid-na1.services.adobe.com',
    });
}

export function getAuthServicesUrl(getEnvironment: EnvironmentMapperFn) {
  return () =>
    byEnvironment(getEnvironment)<string>({
      [ENVIRONMENTS.local]: () => 'http://localhost.corp.adobe.com:3000',
      [ENVIRONMENTS.stage]: () => 'https://auth-stg1.services.adobe.com',
      [ENVIRONMENTS.prod]: () => 'https://auth.services.adobe.com',
    });
}

export function getAdobeidServicesUrl(getEnvironment: EnvironmentMapperFn) {
  return () =>
    byEnvironment(getEnvironment)<string>({
      [ENVIRONMENTS.local]: () => 'http://localhost.corp.adobe.com:3000',
      [ENVIRONMENTS.stage]: () =>
        ' https://adobeid-na1-stg1.services.adobe.com',
      [ENVIRONMENTS.prod]: () => 'https://adobeid-na1.services.adobe.com',
    });
}

export function getAppUrl(getEnvironment: EnvironmentMapperFn) {
  return () =>
    byEnvironment(getEnvironment)<string>({
      [ENVIRONMENTS.local]: () => 'https://localhost.corp.adobe.com:25000',
      [ENVIRONMENTS.stage]: () => 'https://auth-light.identity-stage.adobe.com',
      [ENVIRONMENTS.prod]: () => 'https://auth-light.identity.adobe.com',
    });
}

export function getPopupUrl(getEnvironment: EnvironmentMapperFn) {
  return () =>
    byEnvironment(getEnvironment)<string>({
      [ENVIRONMENTS.local]: () =>
        'https://localhost.corp.adobe.com:25000/wrapper-popup-helper/index.html',
      [ENVIRONMENTS.stage]: () =>
        'https://auth-light.identity-stage.adobe.com/wrapper-popup-helper/index.html',
      [ENVIRONMENTS.prod]: () =>
        'https://auth-light.identity.adobe.com/wrapper-popup-helper/index.html',
    });
}

export const sentryUrl = (
  getEnvironment: EnvironmentMapperFn,
  forceStage = false
) =>
  function sentryUrl(variant: string) {
    const envUrl = getAppUrl(
      forceStage ? () => ENVIRONMENTS.stage : getEnvironment
    )();
    const url = new URL(envUrl || '');
    url.hash = variant;

    return url;
  };

export function create(getEnvironment: EnvironmentMapperFn) {
  return {
    getApiUrl: getApiUrl(getEnvironment),
    getAppUrl: getAppUrl(getEnvironment),
    getFederatedUrl: getFederatedUrl(getEnvironment),
    getAuthServicesUrl: getAuthServicesUrl(getEnvironment),
    getAdobeidServicesUrl: getAdobeidServicesUrl(getEnvironment),
    getPopupUrl: getPopupUrl(getEnvironment),
    sentryUrl: sentryUrl(getEnvironment),
  };
}
