type ObjectValues<T> = T[keyof T];
export const ENVIRONMENTS = {
  local: 'local',
  stage: 'stage',
  prod: 'prod',
} as const;

export type Environment = ObjectValues<typeof ENVIRONMENTS>;

export type EnvironmentMap<T> = Record<Environment, () => T>;

export type EnvironmentMapperFn = (url?: string) => Environment;

export function environmentMapper(map: {
  [url: string]: Environment;
}): EnvironmentMapperFn {
  return (url: string = window.origin) => map[url] ?? ENVIRONMENTS.prod;
}

const isEnvironment =
  (getEnvironment: EnvironmentMapperFn) =>
  (env: Environment): boolean => {
    return getEnvironment() === env;
  };

export function byEnvironment(getEnvironment: EnvironmentMapperFn) {
  return function byEnvironment<T>(envToFnMap: EnvironmentMap<T>): T {
    const env = getEnvironment();
    return envToFnMap[env]();
  };
}

export default function (getEnvironment: EnvironmentMapperFn) {
  return {
    isEnvironment: isEnvironment(getEnvironment),
    byEnvironment: byEnvironment(getEnvironment),
  };
}
